html,
body {
  color: white;
  background-color: black;
}

.tokenizer,
.parser,
.bounds-checker {
  position: relative;
  min-height: 10ex;
  width: fit-content;
  margin-right: auto;
}

.overlay {
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.618);
}

/* ".overlay_panel" */
.overlay > * {
  display: table;

  margin: auto;
  padding: 1ex 1em;
  min-width: 61.8%;
  min-height: 61.8%;
}

.overlay_content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.lexer_error {
  color: #f33;
  text-shadow: -1px -1px 0 black, -1px 1px 0 black, 1px -1px 0 black,
    1px 1px black;
  background-color: #033;
  font-size: 1.618rem;
}

.lexemes_display {
  display: flex;
  flex-wrap: wrap;
}

.lexeme_display {
  display: inline-block;
  margin: 0 1em 1ex 0;
  border: 1px solid;
  padding: 0.5ex 0.5em;
}

.flowtree,.bounded-abstraction {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
}

.flowtree-node, .flowtree-link-label, .bounded-scope-label, .bounded-function, .bounded-argument {
  text-align: center;
  border: 1px solid;
  margin: 0 0.2em;
}

.flowtree-link, .bounded-variable, .unbound-variable {
  padding: 0 0.3em;
}

.flowtree-links, .bounded-application, .bounded-scope {
  display: flex;
  margin: 0.3ex 0;
  justify-content: space-around;
  align-items: flex-start;
}

.unbound-variable {
  color: #fff;
  background-color: #600;
}
