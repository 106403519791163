html, body {
  color: #fff;
  background-color: #000;
}

.tokenizer, .parser, .bounds-checker {
  width: fit-content;
  min-height: 10ex;
  margin-right: auto;
  position: relative;
}

.overlay {
  z-index: 10;
  background-color: #0000009e;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay > * {
  min-width: 61.8%;
  min-height: 61.8%;
  margin: auto;
  padding: 1ex 1em;
  display: table;
}

.overlay_content {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.lexer_error {
  color: #f33;
  text-shadow: -1px -1px #000, -1px 1px #000, 1px -1px #000, 1px 1px #000;
  background-color: #033;
  font-size: 1.618rem;
}

.lexemes_display {
  flex-wrap: wrap;
  display: flex;
}

.lexeme_display {
  border: 1px solid;
  margin: 0 1em 1ex 0;
  padding: .5ex .5em;
  display: inline-block;
}

.flowtree, .bounded-abstraction {
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  display: flex;
}

.flowtree-node, .flowtree-link-label, .bounded-scope-label, .bounded-function, .bounded-argument {
  text-align: center;
  border: 1px solid;
  margin: 0 .2em;
}

.flowtree-link, .bounded-variable, .unbound-variable {
  padding: 0 .3em;
}

.flowtree-links, .bounded-application, .bounded-scope {
  justify-content: space-around;
  align-items: flex-start;
  margin: .3ex 0;
  display: flex;
}

.unbound-variable {
  color: #fff;
  background-color: #600;
}
/*# sourceMappingURL=index.869b807a.css.map */
